import React from 'react';
import find from 'lodash/find';
import uniqueId from 'lodash/uniqueId';
import { Separator } from './QueryFilter';

export type Filter = {
  id: string;
  key: string;
  comparator: string;
  description?: string;
  value: string;
  permanent?: boolean;
  raw?: string;
};

function createQuery(filter: Filter) {
  return filter.key.concat(filter.comparator).concat(filter.value);
}

function constructGroupQueryString(
  filters: Filter[],
  separator: (typeof Separator)[keyof typeof Separator]
) {
  const length = Array.isArray(filters) ? filters.length : 0;
  return filters.reduce((acum, curr, i) => {
    const isLast = i === length - 1;
    const newValue = createQuery(curr);
    return acum.concat(newValue).concat(!isLast ? separator : '');
  }, '');
}

function serialize(filters: Filter[], groups: Group) {
  if (!Array.isArray(filters)) {
    return '';
  }

  const groupsFilter = Object.keys(groups)
    .map((key, i) => {
      const k = Number(key);
      if (Number.isNaN(k)) {
        return undefined;
      }
      const groupFilters = groups[k];
      return ''
        .concat('(')
        .concat(
          constructGroupQueryString(
            groupFilters.filters,
            groupFilters.separator
          )
        )
        .concat(')')
        .concat(groupFilters.join);
    })
    .filter(Boolean)
    .join('');

  const fls = constructGroupQueryString(filters, Separator.AND);
  if (groupsFilter.length > 0 && fls?.length > 0) {
    return ''.concat(groupsFilter).concat('(').concat(fls).concat(')');
  }
  if (groupsFilter.length > 0) {
    return ''.concat(groupsFilter.slice(0, -1));
  }
  if (fls?.length > 0) {
    return ''.concat('(').concat(fls).concat(')');
  }
  return '';
}

export const FiltersContext = React.createContext<{
  filters: Array<Filter>;
  getFilterByKey: (key: string) => Filter | undefined;
  queryParams?: string;
  groups: Group;
  getFilters: () => Filter[];
  isDirty: boolean;
  addFilter: (filter: Filter, options?: { replace?: boolean }) => void;
  addGroupFilters: (filter: Group) => void;
  removeFilter: (id: string) => void;
  removeFilterByKey: (name: string) => void;
  removeFilterByKeys: (names: string[]) => void;
  clearFilters: () => void;
}>({
  filters: [],
  groups: {} as Group,
  queryParams: undefined,
  isDirty: false,
  addFilter: () => {},
  addGroupFilters: () => {},
  removeFilter: () => {},
  removeFilterByKey: () => {},
  removeFilterByKeys: () => {},
  clearFilters: () => {},
  getFilterByKey: () => {
    return undefined;
  },
  getFilters: () => {
    return [];
  },
});

type FiltersProviderProps = {
  initialState?: Filter[];
  initialGroup?: Group;
};

export const useFilters = () => React.useContext(FiltersContext);

type Group = {
  [key: number]: {
    separator: string;
    filters: Filter[];
    join: string;
    isInital?: boolean;
  };
};

function FiltersProvider({
  children,
  initialState,
  initialGroup = {},
}: React.PropsWithChildren<FiltersProviderProps>) {
  const [filters, setFilters] = React.useState<Filter[]>(initialState || []);
  const [groups, setGroups] = React.useState<Group>(initialGroup as Group);
  const initialQueryString = serialize(initialState || [], {} as Group);
  const [queryParams, setQueryString] = React.useState<string | undefined>(
    initialQueryString
  );
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => setIsDirty(false), [location]);

  // TODO: grab data from group as well
  const getFilterByKey = (name: string) => {
    const filter = filters.find((item) => item.key === name);
    return filter;
  };

  const addGroupFilters = React.useCallback((group: Group) => {
    setIsDirty(true);
    setGroups((g) => ({
      ...g,
      ...group,
    }));
  }, []);

  const addFilter = React.useCallback(
    (filter: Filter, options?: { replace?: boolean }) => {
      setIsDirty(true);
      if (!find(filters, { type: filter.key, value: filter.value })) {
        filter.id = uniqueId();
        if (options?.replace) {
          setFilters([
            ...filters.filter((item) => item.key !== filter.key),
            filter,
          ]);
          return;
        }
        setFilters([
          ...filters.filter(
            (item) =>
              `${item.key}${item.comparator}` !==
              `${filter.key}${filter.comparator}`
          ),
          filter,
        ]);
        return;
      }
      const fl = filters.find(
        (item) =>
          item.key === filter.key && item.comparator === filter.comparator
      );
      if (fl) {
        setFilters([
          ...filters.filter(
            (item) =>
              `${item.key}${item.comparator}` !==
              `${filter.key}${filter.comparator}`
          ),
          filter,
        ]);
      }
      if (options?.replace) {
        setFilters([
          ...filters.filter((item) => item.key !== filter.key),
          filter,
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const removeFilter = (id: string) => {
    setIsDirty(true);

    setFilters(filters.filter((item) => item.id !== id));
    removeFromGroup(id);
  };

  const removeFromGroup = (key: string, propertyName: 'id' | 'key' = 'id') => {
    const gr = Object.keys(groups).reduce((acc: any, k) => {
      const group = groups[Number(k)];
      const fl = group?.filters?.filter((x) => x[propertyName] !== key);
      if (fl.length > 0) {
        return {
          ...acc,
          [k]: Object.assign(group, { filters: fl }),
        };
      }
      return { ...acc };
    }, {});
    setGroups(gr);
  };

  const removeFilterByKey = (name: string) => {
    setIsDirty(true);
    setFilters(filters.filter((item) => item.key !== name));
  };

  const removeFilterByKeys = (names: string[]) => {
    setIsDirty(true);
    setFilters(filters.filter((item) => names.indexOf(item.key) === -1));
    names.forEach((x) => {
      removeFromGroup(x, 'key');
    });
  };

  const getFilters = (): Filter[] => {
    return Object.keys(groups)
      .map((key) => {
        const fl = groups[Number(key)];
        return fl.filters;
      })
      .flat()
      .concat(filters) as unknown as Filter[];
  };

  const clearFilters = () => {
    setIsDirty(true);
    const newFilters = filters.filter((item) => item.permanent);
    if (newFilters.length === 0) {
      setFilters(initialState || []);
    } else {
      setFilters(newFilters);
    }
    const gr = Object.keys(groups).reduce((acc: any, k) => {
      const group = groups[Number(k)];
      const fl = group?.filters?.filter((x) => x.permanent);
      if (fl.length > 0) {
        return { ...acc, [k]: Object.assign(group, { filters: fl }) };
      }
      return { ...acc };
    }, {});
    setGroups(gr);
  };

  React.useEffect(() => {
    const qs = serialize(filters, groups);

    setQueryString(qs);
  }, [filters, groups]);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        groups,
        getFilters,
        queryParams,
        isDirty,
        addFilter,
        removeFilter,
        clearFilters,
        removeFilterByKey,
        getFilterByKey,
        removeFilterByKeys,
        addGroupFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export default FiltersProvider;
