import React from 'react';
import { useRouteLoaderData, Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppBar from '../../components/app-bar';
import Menu from './tenant-menu';
import { loader as tenantLoader } from '../../routes/tenant';

export default function TenantAppBar({ children }: React.PropsWithChildren) {
  const [isDrawerOpen, toggleDrawer] = React.useState<boolean>(false);
  const tenant = useRouteLoaderData('tenant') as Awaited<
    ReturnType<ReturnType<typeof tenantLoader>>
  >;
  return (
    <AppBar
      children={
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">
            <Link to="/tenants" style={{ color: 'inherit' }}>
              Dystrybutorzy
            </Link>
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
          <Typography variant="h6">
            {tenant.tenantData.companyName ?? tenant.dbName}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
          {children}
        </Stack>
      }
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      menuList={<Menu toggleDrawer={toggleDrawer} />}
    />
  );
}
