import { Outlet } from 'react-router-dom';
import { FiltersProvider } from '@filmdist/common/hooks';

export default function Cinemas() {
  return (
    <FiltersProvider>
      <Outlet />
    </FiltersProvider>
  );
}
