import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import Root from './routes/root';
import Tenants, { loader as tenantsLoader } from './routes/tenants';
import Cinemas from './routes/cinemas';
import ListCinema, { loader as cinemasLoader } from './routes/cinemas/list';
import AddCinema, {
  action as addCinemaAction,
  loader as addCinemaLoader,
} from './routes/cinemas/add';
import { action as deleteCinemaAction } from './routes/cinemas/delete';
import TenantLayout, { loader as tenantLoader } from './routes/tenant';
import TenantCinemas, {
  loader as tenantCinemasLoader,
} from './routes/tenant/cinemas';
import ErrorPage from './error-page';
import { Auth0Provider } from '@filmdist/common/Auth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/tenants" />,
      },
      {
        path: 'tenants',
        element: <Tenants />,
        loader: tenantsLoader(queryClient),
      },
      {
        path: 'cinemas',
        element: <Cinemas />,
        children: [
          {
            index: true,
            element: <ListCinema />,
            loader: cinemasLoader(queryClient),
          },
          {
            path: 'add',
            element: <AddCinema />,
            loader: addCinemaLoader(queryClient),
            action: addCinemaAction(queryClient),
          },
          {
            path: ':id/delete',
            action: deleteCinemaAction(queryClient),
          },
        ],
      },
      {
        path: 'tenant/:tenantId',
        element: <TenantLayout />,
        id: 'tenant',
        loader: tenantLoader(queryClient),
        children: [
          {
            path: 'cinemas',
            element: <TenantCinemas />,
            loader: tenantCinemasLoader(queryClient),
          },
        ],
      },
    ],
  },
]);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider>
      <RouterProvider router={router} />
    </Auth0Provider>
  </React.StrictMode>
);
