import React from 'react';
import {
  Auth0Client,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  PopupConfigOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';

const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE!;

const auth0Client = new Auth0Client({
  clientId: clientId,
  authorizationParams: {
    audience,
    redirect_uri: window.location.origin,
    scope: 'openid profile email offline_access',
  },
  useRefreshTokens: true,
  domain,
  cacheLocation: 'localstorage',
  useRefreshTokensFallback: true,
});

type AppState = {
  returnTo?: string;
};

function stub() {
  throw new Error('You forgot to wrap your component in <Auth0Provider>.');
  // eslint-disable-next-line no-unreachable
  return Promise.resolve();
}

type Auth0ContextContextType = {
  isAuthenticated: boolean;
  user: User;
  isLoading?: boolean;
  isPopupOpen?: boolean;
  loginWithPopup: () => void;
  onRedirectCallback: (appState: AppState) => void;
  loginWithRedirect: (params?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (
    params: GetTokenSilentlyOptions | undefined
  ) => Promise<string>;
  getTokenWithPopup: (
    params: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ) => Promise<string | undefined>;
  logout: (params: LogoutOptions | undefined) => Promise<void>;
};

const initialContext = {
  isAuthenticated: false,
  loginWithPopup: stub,
  onRedirectCallback: stub,
  loginWithRedirect: stub,
  getTokenSilently: () => Promise.resolve(''),
  getTokenWithPopup: () => Promise.resolve(''),
  logout: stub,
  user: {},
};

const Auth0Context =
  React.createContext<Auth0ContextContextType>(initialContext);

export function useAuth0() {
  const context = React.useContext(Auth0Context);
  if (!context) {
    throw new Error('You forgot to wrap your component in <Auth0Provider>.');
  }
  return context;
}

export const getTokenSilently = async () => {
  return await auth0Client.getTokenSilently();
};

export const onRedirectCallback = (appState?: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

function Auth0Provider({ children }: React.PropsWithChildren<unknown>) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState<User>({});
  const [isLoading, setLoading] = React.useState(true);
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  React.useEffect(() => {
    (async function () {
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0Client.handleRedirectCallback(
          window.location.href
        );

        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        setUser(user || {});
      }

      setLoading(false);
    })();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
      const user = await auth0Client.getUser();
      setUser(user || {});
      setIsAuthenticated(true);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setIsAuthenticated(true);
    setUser(user || {});

    setLoading(false);
  };
  if (!isLoading && !isAuthenticated) {
    auth0Client.loginWithRedirect({});
    return null;
  }

  const logout = (params: LogoutOptions | undefined) => {
    return auth0Client.logout(params);
  };

  const getTokenSilently = (params: GetTokenSilentlyOptions | undefined) => {
    return auth0Client.getTokenSilently(params);
  };

  const getTokenWithPopup = (
    params: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ) => {
    return auth0Client.getTokenWithPopup(params, config);
  };

  const loginWithRedirect = (params: RedirectLoginOptions | undefined) => {
    return auth0Client.loginWithRedirect(params);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        onRedirectCallback: handleRedirectCallback,
        getTokenSilently,
        getTokenWithPopup,
        loginWithRedirect,
        logout,
      }}
    >
      {isAuthenticated && children}
    </Auth0Context.Provider>
  );
}

export default Auth0Provider;
