import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { getTokenSilently } from '@filmdist/common/Auth';
import omit from 'lodash/omit';

export const makeApiCall = async <T = any>(
  url: string,
  options?: { body?: any; isRaw?: boolean } & AxiosRequestConfig
): Promise<T> => {
  const token = await getTokenSilently();
  const { body } = options || {};

  try {
    const response = await axios({
      validateStatus: function (status) {
        return status >= 200 && status < 300; // default
      },
      url: `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      withCredentials: true,
      // mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...options?.headers,
      },
      ...(options && omit(options, 'headers')),
      data: body,
    });
    if (options?.isRaw) {
      return response as unknown as T;
    }
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return Promise.reject(error.response?.data);
  }
};

export type GetParams = {
  pageNumber: number;
  pageSize: number;
  sorts?: string;
  filters?: string;
};
