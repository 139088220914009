import React from 'react';
import { PatternFormat } from 'react-number-format';

export interface NipFormatProps {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
  value?: any;
  displayText?: boolean;
}

const format = '### ### ## ##';

const NipFormat = React.forwardRef((props: NipFormatProps, ref) => {
  const { onChange, ...other } = props;
  if (props.displayText) {
    return (
      <PatternFormat format={format} value={props.value} displayType="text" />
    );
  }
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange &&
          onChange({
            target: {
              name: props.name || '',
              value: values.value,
            },
          });
      }}
      format={format}
    />
  );
});

export default NipFormat;
