import React from 'react';
import AppBar from '../components/app-bar';
import Menu from './menu';

export default function AdminAppBar({ children }: React.PropsWithChildren) {
  const [isDrawerOpen, toggleDrawer] = React.useState<boolean>(false);
  return (
    <AppBar
      children={children}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      menuList={<Menu toggleDrawer={toggleDrawer} />}
    />
  );
}
