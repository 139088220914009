import { ActionFunctionArgs, redirect, Form } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeApiCall } from '../../utils/api/makeApiCall';
import { cinemasKeys } from './list';

const deleteCinema = (id: string) =>
  makeApiCall(`/v1/admin/cinemas/${id}`, { method: 'DELETE' });

export const action =
  (queryClient: QueryClient) =>
  async ({ params }: ActionFunctionArgs) => {
    if (!params.id) {
      throw new Error('Delete cinema error: empty id');
    }
    await deleteCinema(params.id);
    queryClient.invalidateQueries({ queryKey: cinemasKeys.all });
    return redirect('..');
  };

export default function Delete(props: { id: string }) {
  return (
    <Form
      method="DELETE"
      action={`${props.id}/delete`}
      onSubmit={(event) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Czy na pewno chcesz usunąć kino?')) {
          event.preventDefault();
        }
      }}
    >
      <IconButton type="submit">
        <DeleteIcon />
      </IconButton>
    </Form>
  );
}
