import React from 'react';
import debounce from 'lodash/debounce';

type ResultBox<T> = { v: T };

function useConstant<T>(fn: () => T): T {
  const ref = React.useRef<ResultBox<T>>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}

const useDebouncedSearch = (onChange: (value: string) => void) => {
  const [inputText, setInputText] = React.useState<string | undefined>('');

  const debouncedSearchFunction = useConstant(() => debounce(onChange, 500));

  React.useEffect(() => {
    debouncedSearchFunction(inputText || '');
  }, [debouncedSearchFunction, inputText]);

  return {
    inputText,
    setInputText,
  };
};

export default useDebouncedSearch;
