import { UseMutationResult } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export type LocationState = {
  backLocation?: string;
};

export const PaginationSearchKeys = {
  PAGE: 'pageNumber',
  PAGE_SIZE: 'pageSize',
  SORT: 'sort',
} as const;

export function getPaginationFromQuery(urlSearchParams: URLSearchParams) {
  const pageNumber = urlSearchParams.get(PaginationSearchKeys.PAGE)
    ? Number(urlSearchParams.get(PaginationSearchKeys.PAGE))
    : 1;
  const pageSize = urlSearchParams.get(PaginationSearchKeys.PAGE_SIZE)
    ? Number(urlSearchParams.get(PaginationSearchKeys.PAGE_SIZE))
    : 10;
  return {
    pageNumber,
    pageSize,
  };
}

export function createPaginationUrlSearchParams({
  pageNumber = 0,
  pageSize = 0,
  sort = '',
}: Partial<{ pageNumber: number; pageSize: number; sort: string }>) {
  const search = new URLSearchParams();

  const searchParams = [
    { key: PaginationSearchKeys.PAGE, value: pageNumber },
    { key: PaginationSearchKeys.PAGE_SIZE, value: pageSize },
    { key: PaginationSearchKeys.SORT, value: sort },
  ]
    .filter((i) => i)
    .reduce((acc, curr) => {
      if (curr?.value) {
        acc.append(curr.key, curr.value.toString());
      }
      return acc;
    }, search);
  return searchParams.toString();
}

export function createPaginationHandlers({
  navigate,
  location,
}: {
  navigate: ReturnType<typeof useNavigate>;
  location: ReturnType<typeof useLocation>;
}) {
  const handlePaginationChange = (
    variables: Partial<{ pageNumber: number; pageSize: number; sort: string }>
  ) => {
    const search = createPaginationUrlSearchParams({
      ...variables,
    });

    navigate({
      ...location,
      search,
    });
  };
  return {
    onPaginationChange: handlePaginationChange,
  };
}
