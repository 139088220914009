export const Comparator = {
  LESS: '<',
  LESS_OR_EQUAL: '<=',
  GREATER: '>',
  GREATER_OR_EQUAL: '>=',
  EQUAL: '==',
  NOT_EQUAL: '!=',
} as const;

type ComparatorType = typeof Comparator;

export type Filter = {
  id?: string;
  key: string;
  comparator: typeof Comparator[keyof ComparatorType];
  description?: string;
  value: string;
  permanent?: boolean;
  raw?: string;
  or?: Omit<Filter, 'and' | 'or' | 'between'>[];
  and?: Omit<Filter, 'and' | 'or' | 'between'>[];
  between?: Omit<Filter, 'and' | 'or' | 'between'>;
};

export const Separator = {
  AND: ';',
  OR: ',',
};

export function parser(items: Filter[]) {
  if (!Array.isArray(items)) {
    return '';
  }
  const queryString = items.reduce((acum, curr) => {
    const currentFilterString = createQuery(curr);
    const isAndGroup = Array.isArray(curr?.and) ? curr.and.length > 0 : false;
    const isOrGroup = Array.isArray(curr?.or) ? curr.or.length > 0 : false;
    if (!Boolean(curr.between) && !isOrGroup && !isAndGroup) {
      return acum
        .concat(acum.length > 0 ? Separator.AND : '')
        .concat('(')
        .concat(currentFilterString)
        .concat(')');
    }
    if (curr.between) {
      const queryBetween = createQuery(curr.between);
      acum = acum
        .concat(Separator.AND)
        .concat(`(${currentFilterString}${Separator.AND}${queryBetween})`);
    }
    if (isAndGroup && Array.isArray(curr?.and)) {
      const groups = constructGroupQueryString(curr.and, Separator.AND);
      acum = acum
        .concat(Separator.OR)
        .concat(`(${currentFilterString}${Separator.AND}${groups})`);
    }
    if (isOrGroup && Array.isArray(curr?.or)) {
      const groups = constructGroupQueryString(curr.or, Separator.OR);
      acum = acum
        .concat(Separator.OR)
        .concat(`(${currentFilterString}${Separator.OR}${groups})`);
    }

    return acum;
  }, '');

  return queryString;
}

function createQuery(filter: Filter) {
  return filter.key.concat(filter.comparator).concat(filter.value);
}

function constructGroupQueryString(
  groupFilter: Omit<Filter, 'and' | 'or' | 'between'>[],
  separator: typeof Separator[keyof typeof Separator]
) {
  const length = Array.isArray(groupFilter) ? groupFilter.length : 0;
  return groupFilter.reduce((acum, curr, i) => {
    const isLast = i === length - 1;
    const newValue = createQuery(curr);
    return acum.concat(newValue).concat(!isLast ? separator : '');
  }, '');
}
