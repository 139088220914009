import React from 'react';
import { useTable, useSortBy, Row } from 'react-table';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import { RemoteDataTableProps } from './DataTable.types';

const TableFooterCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.grey[100],
}));

const defaultPropGetter = () => ({});

const StorageKey = {
  POSITION: 'impuls-scroll-pos:page',
  KEYS: 'impuls-scroll-keys',
};

function createStorage(
  storage:
    | WindowLocalStorage['localStorage']
    | WindowSessionStorage['sessionStorage']
) {
  return {
    addItem: (value: number) => {
      const pageKey = StorageKey.POSITION.replace(
        ':page',
        window.location.pathname
      );

      try {
        const keys = JSON.parse(
          storage.getItem(StorageKey.KEYS) || '[]'
        ) as string[];
        if (keys.length > 0) {
          keys.forEach((key) => {
            storage.removeItem(key);
          });
        }

        storage.setItem(StorageKey.KEYS, JSON.stringify([pageKey]));
      } catch (e) {
        console.error('Fails on parsing storage data', e);
      }

      storage.setItem(pageKey, value?.toString());
      return value?.toString();
    },
    getItem: () => {
      return Number(
        storage.getItem(
          StorageKey.POSITION.replace(':page', window.location.pathname)
        )
      );
    },
    removeItem: () => {
      storage.removeItem(
        StorageKey.POSITION.replace(':page', window.location.pathname)
      );
    },
  };
}

const scrollStorage = createStorage(window.sessionStorage);

const debouncedAddItem = debounce(scrollStorage.addItem, 300);

function handleScrollPosition() {
  const offsetY = window.pageYOffset;
  debouncedAddItem(offsetY);
}

function restoreScrollPosition() {
  const scrollPos = scrollStorage.getItem();
  window.scrollTo(0, scrollPos);
}

function RemoteDataTable<T extends Record<string, unknown>>({
  columns,
  data,
  page = 0,
  onPageChange,
  rowsPerPage = 10,
  onRowsPerPage,
  onRowClick,
  onHeaderClick = () => {},
  onSetField,
  hasPagination = true,
  loading = false,
  size = 'medium',
  getRowProps = defaultPropGetter,
  ...options
}: RemoteDataTableProps<T>) {
  const hasFooter = some(columns, 'Footer');

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onPageChange?.(page);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    onRowsPerPage?.(parseInt(event.target.value, 10));
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollPosition);
    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, []);

  React.useEffect(() => {
    if (data?.items) {
      restoreScrollPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.items]);

  const tableData = React.useMemo(() => (data ? data.items : []), [data]);

  const { getTableProps, headerGroups, rows, footerGroups, prepareRow } =
    useTable<T>(
      {
        columns,
        data: tableData,
        manualSortBy: true,
        onSetField,
        ...options,
      },
      useSortBy
    );
  return (
    <>
      <Table {...getTableProps()} size={size}>
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => onHeaderClick(column)}
                  style={{
                    width: column.width,
                    minWidth: column.minWidth,
                    padding: '12px 8px',
                  }}
                  align={column.align === 'right' ? 'right' : 'left'}
                >
                  {column.render('Header')}
                  <span>
                    {column.sortDirection === 'ASC' ? (
                      <ArrowUpwardIcon style={{ verticalAlign: 'top' }} />
                    ) : column.sortDirection === 'DESC' ? (
                      <ArrowDownwardIcon style={{ verticalAlign: 'top' }} />
                    ) : null}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {loading && (
            <tr>
              <td colSpan={columns.length}>
                <Box padding={4}>
                  <Typography variant="body1" align="center">
                    Trwa ładowanie...
                  </Typography>
                </Box>
              </td>
            </tr>
          )}
          {rows.map((row: Row<T>) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps(getRowProps(row))}
                onClick={() => onRowClick && onRowClick(row)}
                hover={!!onRowClick}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell
                      {...cell.getCellProps([
                        {
                          padding: cell.column.padding,
                          align: cell.column.align ?? 'left',
                        },
                      ])}
                      style={{ padding: '12px 8px' }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {hasFooter && (
          <TableFooter>
            {footerGroups.map((group) => (
              <TableRow {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <TableFooterCell {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </TableFooterCell>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </Table>
      {hasPagination && (
        <TablePagination
          component="div"
          count={data?.totalCount || 0}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
}

export default RemoteDataTable;
