import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  useNavigate,
  useLocation,
  useLoaderData,
  useSearchParams,
  Link,
} from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataTable as Table,
  EnhancedColumn,
  createPaginationHandlers,
  getPaginationFromQuery,
  CellProps,
} from '@filmdist/common/Table';
import { FilterInput } from '@filmdist/common/FilterInput';
import { useFilters } from '@filmdist/common/hooks';
import { makeApiCall } from '../../utils/api/makeApiCall';
import AppBar from '../../admin/admin-app-bar';
import { Paginated } from '../../types';
import Delete from './delete';

const ENDPOINTS = {
  get: '/v1/admin/cinemas',
};

type CinemasResponse = Paginated<Cinema>;

export type CinemasParams = Partial<{
  pageNumber: number;
  pageSize: number;
  sorts?: string;
}>;

export const cinemasKeys = {
  all: ['cinemas'] as const,
  lists: () => [...cinemasKeys.all, 'list'] as const,
  list: (filters?: string) => [...cinemasKeys.lists(), { filters }] as const,
  details: () => [...cinemasKeys.all, 'detail'] as const,
  detail: (id: string) => [...cinemasKeys.details(), id] as const,
};

export const fetchCinemas = (params?: CinemasParams) => {
  const r = makeApiCall<CinemasResponse>(ENDPOINTS.get, { params });

  return r;
};

export type Cinema = {
  id: string;
  name: string;
  city: string;
};

const cinemasQuery = (params?: CinemasParams) => ({
  queryKey: cinemasKeys.list(
    new URLSearchParams(params as Record<string, string>).toString()
  ),
  queryFn: async () => {
    const tenants = await fetchCinemas(params);
    return tenants;
  },
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const url = new URL(request.url);
    const query = cinemasQuery(Object.fromEntries(url.searchParams));
    return (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as Promise<CinemasResponse>;
  };

export default function List() {
  const [filterValue, setFilterValue] = React.useState('');
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const filters = useFilters();
  const query = new URLSearchParams(location.search);
  const { pageSize, pageNumber } = getPaginationFromQuery(query);
  const [params, setParams] = React.useState({
    pageSize,
    pageNumber,
    filters: filters.queryParams,
  });
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  const { onPaginationChange } = createPaginationHandlers({
    navigate,
    location,
  });

  const handleRowsPerPageChange = (pageSize: number) => {
    setParams({ ...params, pageSize });
    onPaginationChange({ ...params, pageSize });
  };

  const handlePageChange = (pageNumber: number) => {
    setParams({ ...params, pageNumber: pageNumber + 1 });
    onPaginationChange({ ...params, pageNumber: pageNumber + 1 });
  };

  const page = isNaN(Number(params.pageNumber) - 1)
    ? 0
    : Number(params.pageNumber) - 1;
  const rowsPerPage = isNaN(Number(params.pageSize))
    ? 10
    : Number(params.pageSize);

  const columns: EnhancedColumn<Cinema>[] = React.useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        Header: 'Akcje',
        accessor: 'id',
        Cell(props: CellProps<Cinema>) {
          return (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Delete id={props.value} />
            </Stack>
          );
        },
        align: 'right',
      },
    ],
    []
  );

  const handleFilterChange = (value: string) => {
    setFilterValue(value);
    if (value) {
      filters.addGroupFilters({
        0: {
          separator: ',',
          join: ';',
          filters: [
            {
              id: uniqueId(),
              key: 'name',
              comparator: '==',
              value: `%${value}%`,
            },
            {
              id: uniqueId(),
              key: 'city',
              comparator: '==',
              value: `%${value}%`,
            },
            {
              id: uniqueId(),
              key: 'state',
              comparator: '==',
              value: `%${value}%`,
            },
          ],
        },
      });
    } else {
      filters.clearFilters();
    }
  };

  React.useEffect(() => {
    if (filters.isDirty && filters.queryParams !== params.filters) {
      setParams({ ...params, pageNumber: 1, filters: filters.queryParams });
      setSearchParams((prev) => {
        if (filters.queryParams) {
          prev.append('filters', filters.queryParams);
        } else {
          prev.delete('filters');
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.queryParams, filters.isDirty]);

  React.useEffect(() => {
    return () => filters.clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar>
        <Typography variant="h6">Kina</Typography>
      </AppBar>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        sx={{ padding: 2, borderBottom: '1px solid', borderColor: 'divider' }}
      >
        <Button
          component={Link}
          to="add"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Dodaj kino
        </Button>

        <FilterInput
          onChange={handleFilterChange}
          defaultValue={filterValue}
          data-cy="payerSearch"
        />
      </Stack>
      <Table<Cinema>
        columns={columns}
        data={initialData}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPage={handleRowsPerPageChange}
        // onRowClick={(row) => navigate(`/tenant/${row.original.id}/cinemas`)}
        // getRowProps={() => ({
        //   style: {
        //     cursor: 'pointer',
        //   },
        // })}
      />
    </>
  );
}
