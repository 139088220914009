import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  useNavigate,
  useLocation,
  useLoaderData,
  generatePath,
} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SyncIcon from '@mui/icons-material/Sync';
import {
  DataTable as Table,
  CellProps,
  EnhancedColumn,
  createPaginationHandlers,
  getPaginationFromQuery,
} from '@filmdist/common/Table';
import { makeApiCall } from '../../utils/api/makeApiCall';
import AppBar from '../../components/tenant/tenant-app-bar';
import { Paginated } from '../../types';
import { tenantKeys } from '../tenants';

const ENDPOINTS = {
  get: '/v1/admin/:tenantId/cinemas',
};

type TenantCinemasResponse = Paginated<Cinema>;

export type TenantCinemasParams = Partial<{
  pageNumber: number;
  pageSize: number;
  sorts?: string;
}>;

export const fetchCinemas = (id: string, params?: TenantCinemasParams) => {
  const r = makeApiCall<TenantCinemasResponse>(
    generatePath(ENDPOINTS.get, { tenantId: id }),
    { params }
  );

  return r;
};

export type Cinema = {
  name: string;
  city: string | null;
  commonKeyId: string | null;
};

const cinemasQuery = (id: string, params?: TenantCinemasParams) => ({
  queryKey: tenantKeys.cinemas(
    id,
    new URLSearchParams(params as Record<string, string>).toString()
  ),
  queryFn: async () => {
    const cinemas = await fetchCinemas(id, params);
    return cinemas;
  },
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ request, params }: { request: Request; params: any }) => {
    const url = new URL(request.url);
    const query = cinemasQuery(
      params.tenantId,
      Object.fromEntries(url.searchParams)
    );
    return (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as Promise<TenantCinemasResponse>;
  };

export default function TenantCinemas() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { pageSize, pageNumber } = getPaginationFromQuery(query);
  const [params, setParams] = React.useState({
    pageSize,
    pageNumber,
  });
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  const { onPaginationChange } = createPaginationHandlers({
    navigate,
    location,
  });

  const handleRowsPerPageChange = (pageSize: number) => {
    setParams({ ...params, pageSize });
    onPaginationChange({ ...params, pageSize });
  };

  const handlePageChange = (pageNumber: number) => {
    setParams({ ...params, pageNumber: pageNumber + 1 });
    onPaginationChange({ ...params, pageNumber: pageNumber + 1 });
  };

  const page = isNaN(Number(params.pageNumber) - 1)
    ? 0
    : Number(params.pageNumber) - 1;
  const rowsPerPage = isNaN(Number(params.pageSize))
    ? 10
    : Number(params.pageSize);

  const columns: EnhancedColumn<Cinema>[] = React.useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        Header: 'Miejscowość',
        accessor: 'city',
      },
      {
        Header: 'Zsynchronizowane',
        accessor: 'commonKeyId',
        Cell: (rawData: CellProps<Cinema>) => {
          return rawData.row.original.commonKeyId ? <SyncIcon /> : null;
        },
      },
    ],
    []
  );

  return (
    <>
      <AppBar>
        <Typography variant="h6">Kina</Typography>
      </AppBar>
      <Table<Cinema>
        columns={columns}
        data={initialData}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPage={handleRowsPerPageChange}
        // getRowProps={() => ({
        //   style: {
        //     cursor: 'pointer',
        //   },
        // })}
      />
    </>
  );
}
