import {
  ActionFunctionArgs,
  useSubmit,
  useLoaderData,
  redirect,
} from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { ObjectSchema, object, string, number } from 'yup';
import { serialize } from 'object-to-formdata';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PostalCode } from '@filmdist/common/NumberFormat';
import { states } from '../../utils/administrative-resources';
import { makeApiCall } from '../../utils/api/makeApiCall';
import { CinemaNetwork, cinemaNetworks } from '../../types';
import { cinemasKeys } from './list';

const ENDPOINTS = {
  post: '/v1/admin/cinemas',
  getNetworks: '/v1/common/dictionaries/networks',
};

type CinemaFormData = {
  name: string;
  zipCode: string;
  city: string;
  street: string;
  streetNumber: string;
  apartmentNumber: string | null;
  state: string;
  email: string;
  phone: string | null;
  screen: number | null;
  seats: number | null;
  cinemaNetwork: CinemaNetwork | null;
};

const validationSchema: ObjectSchema<CinemaFormData> = object({
  name: string().required('Nazwa jest wymagana'),
  zipCode: string().required('Kod pocztowy jest wymagany'),
  city: string().required('Miejscowość jest wymagana'),
  street: string().required('Ulica jest wymagana'),
  streetNumber: string().required('Numer jest wymagany'),
  apartmentNumber: string().nullable().defined(),
  state: string().required('Województwo jest wymagane'),
  email: string().required('Email jest wymagany'),
  phone: string().nullable().defined(),
  screen: number().defined().nullable(),
  seats: number().defined().nullable(),
  cinemaNetwork: string()
    .oneOf([...cinemaNetworks])
    .defined()
    .nullable(),
});

export const addCinema = (body: FormData) => {
  const r = makeApiCall(ENDPOINTS.post, { method: 'POST', body });

  return r;
};

export const fetchNetworks = () => {
  const r = makeApiCall(ENDPOINTS.getNetworks);

  return r;
};

export const networkKeys = {
  all: ['networks'] as const,
  lists: () => [...networkKeys.all, 'list'] as const,
};

export type Network = {
  name: string;
  code: string;
};

const networksQuery = () => ({
  queryKey: networkKeys.lists(),
  queryFn: async () => {
    const networks = await fetchNetworks();
    return networks;
  },
});

export const loader = (queryClient: QueryClient) => async () => {
  const query = networksQuery();
  return (queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))) as Promise<Network[]>;
};

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    await addCinema(formData);
    queryClient.invalidateQueries({ queryKey: cinemasKeys.all });
    return redirect('..');
  };

export default function Add() {
  const submit = useSubmit();
  const cinemaNetworks = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;
  const { touched, values, errors, handleChange, handleBlur, handleSubmit } =
    useFormik<CinemaFormData>({
      initialValues: {
        cinemaNetwork: null,
        city: '',
        name: '',
        screen: null,
        seats: null,
        state: '',
        street: '',
        streetNumber: '',
        zipCode: '',
        apartmentNumber: null,
        email: '',
        phone: null,
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        submit(serialize(values), { method: 'post' });
      },
    });

  return (
    <Container maxWidth="md" style={{ height: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Dane podstawowe</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nazwa"
                error={!!touched.name && !!errors.name}
                helperText={!!touched.name && errors.name}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ulica"
                name="street"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.street}
                error={!!touched.street && !!errors.street}
                helperText={!!touched.street && errors.street}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Numer budynku"
                name="streetNumber"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.streetNumber}
                error={!!touched.streetNumber && !!errors.streetNumber}
                helperText={!!touched.streetNumber && errors.streetNumber}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Numer lokalu"
                name="apartmentNumber"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.apartmentNumber ?? ''}
                error={!!touched.apartmentNumber && !!errors.apartmentNumber}
                helperText={
                  !!touched.apartmentNumber && errors.apartmentNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Kod pocztowy"
                name="zipCode"
                required
                error={!!touched.zipCode && !!errors.zipCode}
                helperText={!!touched.zipCode && errors.zipCode}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zipCode}
                InputProps={{
                  inputComponent: PostalCode as any,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Miejscowość"
                name="city"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                error={!!touched.city && !!errors.city}
                helperText={!!touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Województwo"
                name="state"
                required
                fullWidth
                select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                error={!!touched.state && !!errors.state}
                helperText={!!touched.state && errors.state}
              >
                {states.map((state, index) => (
                  <MenuItem value={state} key={index}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numer telefonu"
                name="phone"
                fullWidth
                onChange={handleChange}
                value={values.phone ?? ''}
                error={!!touched.phone && !!errors.phone}
                helperText={!!touched.phone && errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Szczegóły</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Liczba ekranów"
                name="screen"
                error={!!touched.screen && !!errors.screen}
                helperText={!!touched.screen && errors.screen}
                fullWidth
                type="number"
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.screen ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Liczba miejsc"
                name="seats"
                error={!!touched.seats && !!errors.seats}
                helperText={!!touched.seats && errors.seats}
                fullWidth
                type="number"
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.seats ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Sieć"
                name="cinemaNetwork"
                fullWidth
                select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cinemaNetwork ?? ''}
                error={!!touched.cinemaNetwork && !!errors.cinemaNetwork}
                helperText={!!touched.cinemaNetwork && errors.cinemaNetwork}
              >
                {cinemaNetworks.map((cinemaNetwork) => (
                  <MenuItem
                    value={cinemaNetwork.code}
                    key={cinemaNetwork.code}
                  >
                    {cinemaNetwork.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row">
            <Button type="submit" variant="contained" color="primary">
              Zapisz
            </Button>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
}
