import React from 'react';
import { NumericFormat } from 'react-number-format';

export interface InvoiceNumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InvoiceNumberFormat = React.forwardRef(
  (props: InvoiceNumberFormatProps, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
        }}
        allowNegative={false}
        allowLeadingZeros
        decimalScale={0}
      />
    );
  }
);

export default InvoiceNumberFormat;
