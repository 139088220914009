import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useDebouncedSearch from './useDebounce';
import noop from 'lodash/noop';

type FilterInputProps = Omit<Omit<TextFieldProps, 'onChange'>, 'value'> & {
  defaultValue?: string;
  onChange: (v: string) => void;
  'data-cy'?: string;
  placeholder?: string;
};

function FilterInput({
  onChange = noop,
  defaultValue,
  ...rest
}: FilterInputProps) {
  const { placeholder = 'Szukaj...' } = rest;

  const { inputText, setInputText } = useDebouncedSearch(onChange);
  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setInputText('');
    onChange?.('');
  };

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onChange?.(inputText || '');
  };

  React.useEffect(() => {
    setInputText(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        size="small"
        variant="outlined"
        value={inputText}
        onChange={handleChange}
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                sx={{
                  opacity: inputText && inputText.length > 0 ? 1 : 0,
                }}
                onClick={handleClick}
              >
                <ClearIcon
                  sx={{
                    width: 18,
                    height: 18,
                  }}
                />
              </IconButton>

              <SearchIcon />
            </InputAdornment>
          ),
        }}
        {...rest}
        placeholder={placeholder}
      />
    </form>
  );
}

export default FilterInput;
