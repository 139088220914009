export type Paginated<T> = {
  items: T[];
  pageNumber: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
};

export const cinemaNetworks = [
  'cc',
  'helios',
  'multikino',
  'sksil',
  'none',
] as const;
export type CinemaNetwork = (typeof cinemaNetworks)[number];
