import { Outlet, generatePath } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { makeApiCall } from '../../utils/api/makeApiCall';
import { tenantKeys, Tenant } from '../tenants';

const ENDPOINTS = {
  get: '/v1/admin/tenants/:tenantId',
};

export const fetchTenant = (id: string) => {
  const r = makeApiCall<Tenant>(generatePath(ENDPOINTS.get, { tenantId: id }));

  return r;
};

const cinemasQuery = (id: string) => ({
  queryKey: tenantKeys.detail(id),
  queryFn: async () => {
    const tenant = await fetchTenant(id);
    return tenant;
  },
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: any }) => {
    const query = cinemasQuery(params.tenantId);
    return (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as Promise<Tenant>;
  };

export default function TenantIndex() {
  return <Outlet />;
}
