import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { useNavigate, useLocation, useLoaderData } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  DataTable as Table,
  CellProps,
  EnhancedColumn,
  createPaginationHandlers,
  getPaginationFromQuery,
} from '@filmdist/common/Table';
import { makeApiCall } from '../utils/api/makeApiCall';
import AppBar from '../admin/admin-app-bar';
import { Paginated } from '../types';

const ENDPOINTS = {
  get: '/v1/admin/tenants',
};

type TenantsResponse = Paginated<Tenant>;

export type TenantsParams = Partial<{
  pageNumber: number;
  pageSize: number;
  sorts?: string;
}>;

export const tenantKeys = {
  all: ['tenants'] as const,
  lists: () => [...tenantKeys.all, 'list'] as const,
  list: (filters?: string) => [...tenantKeys.lists(), { filters }] as const,
  details: () => [...tenantKeys.all, 'detail'] as const,
  detail: (id: string) => [...tenantKeys.details(), id] as const,
  allCinemas: (id: string) => [...tenantKeys.detail(id), 'cinemas'] as const,
  cinemas: (id: string, filters?: string) =>
    [...tenantKeys.allCinemas(id), { filters }] as const,
};

export const fetchTenants = (params?: TenantsParams) => {
  const r = makeApiCall<TenantsResponse>(ENDPOINTS.get, { params });

  return r;
};

export type Tenant = {
  id: string;
  ownerEmail: string;
  ownerUserId: string;
  host: string;
  dbIp: number;
  dbName: string;
  tenantData: {
    companyName: string | null;
  };
};

const tenantsQuery = (params?: TenantsParams) => ({
  queryKey: tenantKeys.list(
    new URLSearchParams(params as Record<string, string>).toString()
  ),
  queryFn: async () => {
    const tenants = await fetchTenants(params);
    return tenants;
  },
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const url = new URL(request.url);
    const query = tenantsQuery(Object.fromEntries(url.searchParams));
    return (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as Promise<TenantsResponse>;
  };

export default function Tenants() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { pageSize, pageNumber } = getPaginationFromQuery(query);
  const [params, setParams] = React.useState({
    pageSize,
    pageNumber,
  });
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  const { onPaginationChange } = createPaginationHandlers({
    navigate,
    location,
  });

  const handleRowsPerPageChange = (pageSize: number) => {
    setParams({ ...params, pageSize });
    onPaginationChange({ ...params, pageSize });
  };

  const handlePageChange = (pageNumber: number) => {
    setParams({ ...params, pageNumber: pageNumber + 1 });
    onPaginationChange({ ...params, pageNumber: pageNumber + 1 });
  };

  const page = isNaN(Number(params.pageNumber) - 1)
    ? 0
    : Number(params.pageNumber) - 1;
  const rowsPerPage = isNaN(Number(params.pageSize))
    ? 10
    : Number(params.pageSize);

  const columns: EnhancedColumn<Tenant>[] = React.useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'dbName',
        Cell: (rawData: CellProps<Tenant>) => {
          return rawData.row.original.tenantData.companyName || rawData.value;
        },
      },
    ],
    []
  );

  return (
    <>
      <AppBar>
        <Typography variant="h6">Dystrybutorzy</Typography>
      </AppBar>
      <Table<Tenant>
        columns={columns}
        data={initialData}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPage={handleRowsPerPageChange}
        onRowClick={(row) => navigate(`/tenant/${row.original.id}/cinemas`)}
        getRowProps={() => ({
          style: {
            cursor: 'pointer',
          },
        })}
      />
    </>
  );
}
