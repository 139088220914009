import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { Logo } from '@filmdist/common/Logo';

export default function TenantMenu({
  toggleDrawer,
}: {
  toggleDrawer: (state: boolean) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <Box sx={{ paddingLeft: 2, display: 'flex', alignItems: 'center' }}>
        <Logo variant={theme.palette.mode} />
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('../cinemas')}>
            <ListItemText>Kina</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
