import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

export default function AppBar({
  children,
  menuList,
  isDrawerOpen,
  toggleDrawer,
}: React.PropsWithChildren<{
  menuList: React.ReactElement;
  isDrawerOpen: boolean;
  toggleDrawer: (state: boolean) => void;
}>) {
  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Stack spacing={2} direction="row" alignItems="center">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          {children}
        </Stack>
      </Toolbar>
      <Drawer open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
        {menuList}
      </Drawer>
    </MuiAppBar>
  );
}
