import React from 'react';

type LogoProps = {
  variant: 'light' | 'dark';
  className?: string;
};

const Logo: React.FunctionComponent<LogoProps> = ({ variant, className }) => {
  return variant === 'dark' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="57"
      viewBox="0 0 108 57"
      className={className}
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#FFF"
          d="M1.656 37H6.6v-6.072h5.304v-3.624H6.6v-3.648h6.648V19.84H1.656V37zm13.82 0h4.297V24.448h-4.296V37zm2.137-13.752c1.344 0 2.376-.936 2.376-2.208 0-1.272-1.032-2.232-2.376-2.232-1.344 0-2.352.96-2.352 2.232 0 1.272 1.008 2.208 2.352 2.208zM22.985 37h4.272V18.928h-4.272V37zm7.365 0h4.296v-7.272c0-1.368.768-2.112 1.92-2.112.984 0 1.608.624 1.608 1.896V37h4.248v-7.272c0-1.368.768-2.112 1.92-2.112.984 0 1.608.624 1.608 1.896V37h4.296v-8.208c0-3.144-1.728-4.68-4.104-4.68-1.584 0-3.072.624-4.152 2.088-.6-1.368-1.752-2.088-3.408-2.088-1.944 0-3.264 1.056-3.936 2.256v-1.92H30.35V37zm27.428.288c1.896 0 3.288-1.032 3.912-2.328V37h4.296V18.928H61.69v7.296c-.72-1.272-1.896-2.112-3.864-2.112-2.908 0-5.22 2.178-5.324 6.264l-.004.48c0 4.344 2.376 6.432 5.28 6.432zM59.29 34c-1.488 0-2.424-1.08-2.424-3.192v-.192c0-2.184.864-3.264 2.472-3.264 1.584 0 2.472 1.128 2.472 3.24v.192c0 2.136-.96 3.216-2.52 3.216zm9.789 3h4.296V24.448h-4.296V37zm2.136-13.752c1.344 0 2.376-.936 2.376-2.208 0-1.272-1.032-2.232-2.376-2.232-1.344 0-2.352.96-2.352 2.232 0 1.272 1.008 2.208 2.352 2.208zm10.172 14.04c3.48 0 5.688-1.344 5.688-4.32 0-2.613-1.588-3.548-4.874-3.995l-.286-.037c-1.536-.192-2.112-.432-2.112-1.032 0-.576.48-.984 1.416-.984.984 0 1.44.408 1.608 1.248h3.888c-.288-3-2.448-4.056-5.544-4.056-2.904 0-5.376 1.344-5.376 4.128 0 2.543 1.339 3.544 4.5 3.995l.276.037c1.728.24 2.328.528 2.328 1.128 0 .624-.48 1.032-1.536 1.032-1.224 0-1.632-.504-1.776-1.416h-3.96c.096 2.784 2.184 4.272 5.76 4.272zm12.861 0c-2.832 0-4.416-1.368-4.416-4.32v-5.592H88.32v-2.928h1.512v-2.616h4.296v2.616H96.6v2.928h-2.472v5.184c0 .912.456 1.32 1.272 1.32.48 0 .84-.072 1.224-.216v3.264l-.362.093a8.285 8.285 0 0 1-2.014.267z"
        />
        <path
          fill="#05F"
          d="M103.077 37.396c-2.124 0-3.672-1.62-3.672-3.636s1.548-3.636 3.672-3.636c2.16 0 3.78 1.62 3.78 3.636s-1.62 3.636-3.78 3.636z"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="57"
      viewBox="0 0 108 57"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-352-1234h1600V350H-352z" />
        <g fillRule="nonzero">
          <path
            fill="#000"
            d="M1.656 37H6.6v-6.072h5.304v-3.624H6.6v-3.648h6.648V19.84H1.656V37zm13.82 0h4.297V24.448h-4.296V37zm2.137-13.752c1.344 0 2.376-.936 2.376-2.208 0-1.272-1.032-2.232-2.376-2.232-1.344 0-2.352.96-2.352 2.232 0 1.272 1.008 2.208 2.352 2.208zM22.985 37h4.272V18.928h-4.272V37zm7.365 0h4.296v-7.272c0-1.368.768-2.112 1.92-2.112.984 0 1.608.624 1.608 1.896V37h4.248v-7.272c0-1.368.768-2.112 1.92-2.112.984 0 1.608.624 1.608 1.896V37h4.296v-8.208c0-3.144-1.728-4.68-4.104-4.68-1.584 0-3.072.624-4.152 2.088-.6-1.368-1.752-2.088-3.408-2.088-1.944 0-3.264 1.056-3.936 2.256v-1.92H30.35V37zm27.428.288c1.896 0 3.288-1.032 3.912-2.328V37h4.296V18.928H61.69v7.296c-.72-1.272-1.896-2.112-3.864-2.112-2.908 0-5.22 2.178-5.324 6.264l-.004.48c0 4.344 2.376 6.432 5.28 6.432zM59.29 34c-1.488 0-2.424-1.08-2.424-3.192v-.192c0-2.184.864-3.264 2.472-3.264 1.584 0 2.472 1.128 2.472 3.24v.192c0 2.136-.96 3.216-2.52 3.216zm9.789 3h4.296V24.448h-4.296V37zm2.136-13.752c1.344 0 2.376-.936 2.376-2.208 0-1.272-1.032-2.232-2.376-2.232-1.344 0-2.352.96-2.352 2.232 0 1.272 1.008 2.208 2.352 2.208zm10.172 14.04c3.48 0 5.688-1.344 5.688-4.32 0-2.613-1.588-3.548-4.874-3.995l-.286-.037c-1.536-.192-2.112-.432-2.112-1.032 0-.576.48-.984 1.416-.984.984 0 1.44.408 1.608 1.248h3.888c-.288-3-2.448-4.056-5.544-4.056-2.904 0-5.376 1.344-5.376 4.128 0 2.543 1.339 3.544 4.5 3.995l.276.037c1.728.24 2.328.528 2.328 1.128 0 .624-.48 1.032-1.536 1.032-1.224 0-1.632-.504-1.776-1.416h-3.96c.096 2.784 2.184 4.272 5.76 4.272zm12.861 0c-2.832 0-4.416-1.368-4.416-4.32v-5.592H88.32v-2.928h1.512v-2.616h4.296v2.616H96.6v2.928h-2.472v5.184c0 .912.456 1.32 1.272 1.32.48 0 .84-.072 1.224-.216v3.264l-.362.093a8.285 8.285 0 0 1-2.014.267z"
          />
          <path
            fill="#05F"
            d="M103.077 37.396c-2.124 0-3.672-1.62-3.672-3.636s1.548-3.636 3.672-3.636c2.16 0 3.78 1.62 3.78 3.636s-1.62 3.636-3.78 3.636z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
